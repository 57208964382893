import { useCount } from "@/utils/count";
import { createRoute } from "@/assets/data/produce/classify";
import store from "@/store";

const load = async () => {
    await useCount().countBill();
    await useCount().countOrderConfirm();
    await useCount().countPendingWorkflow();
    await useCount().countMyIntentions();
    await useCount().countWww();
};

const productList = [
    createRoute("agricultural", "农业设备", "goods", "manage_product"),
    createRoute("part", "BOOM库", "part", "manage_external_inventory"),
    createRoute("other", "研发&维保", "tool", "manage_product"),
    createRoute("extend", "其它产品", "goods-extend", "manage_product"),
    createRoute("oem", "OEM产品", "oem-goods", "manage_product"),
    createRoute("fertilizer", "肥料护理", "indicator", "manage_product"),
    createRoute("solutions", "室内方案", "produce-task-testing", "manage_product"),
    createRoute("substrateSeedling", "基质种苗", "detection", "manage_product"),
    createRoute("packaging", "包装设计", "category", "manage_product"),
    createRoute("crop", "作物保护", "plant", "manage_product"),
];

export default [
    {
        path: "/fms",
        name: "fms__root__",
        redirect: {
            name: "fms-index",
        },
        component: () => import("@/layouts/default.vue"),
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            await load();
            next();
        },
        meta: {
            parent: "fms",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "fms-index",
                component: () => import("@/views/fms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/fms/customer",
        name: "fms-customer",
        meta: {
            title: "客户管理",
            icon: "enterprise",
            parent: "fms",
            permission: "manage_customer",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/customer/organization",
        children: [
            {
                path: "organization",
                name: "omp-enterprise-organization",
                component: () => import("@/views/omp/enterprise/organization.vue"),
                meta: {
                    title: "企业管理",
                    icon: "organization",
                },
            },
            {
                path: "dashboard",
                name: "omp-enterprise-dashboard",
                component: () => import("@/views/omp/enterprise/dashboard.vue"),
                meta: {
                    title: "园区管理",
                    icon: "dashboard_mng",
                },
            },
            {
                path: "user",
                name: "omp-account-user",
                component: () => import("@/views/omp/account/user.vue"),
                meta: {
                    title: "账号管理",
                    icon: "users",
                },
            },
            {
                path: "other",
                name: "omp-account-other",
                component: () => import("@/views/omp/account/other-user.vue"),
                meta: {
                    title: "散户管理",
                    icon: "user",
                },
            },
            {
                path: "address",
                name: "fms-customer-address",
                component: () => import("@/views/fms/customer/address.vue"),
                meta: {
                    title: "地址管理",
                    icon: "address-mng",
                },
            },
        ],
    },
    {
        path: "/fms/order",
        name: "fms-order",
        meta: {
            title: "合同订单",
            icon: "invoice",
            parent: "fms",
            permission: "manage_order",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            await load();
            next();
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/order/intention",
        children: [
            {
                path: "project",
                name: "fms-order-project",
                component: () => import("@/views/fms/order/project.vue"),
                meta: {
                    title: "项目管理",
                    icon: "project",
                    affix: false,
                },
            },
            {
                path: "project-detail/:id(\\d+)?",
                name: "fms-order-project-detail",
                component: () => import("@/views/fms/order/project-detail.vue"),
                hidden: true,
                meta: {
                    title: "项目详情",
                    icon: "project",
                    affix: false,
                    activeMenu: "/fms/order/project",
                },
            },
            {
                path: "intention",
                name: "fms-order-intention",
                component: () => import("@/views/fms/order/intention"),
                meta: {
                    title: "意向订单",
                    icon: "intention1",
                    affix: false,
                    keepAlive: true,
                },
            },
            {
                path: "intention_edit/:id(\\d+)?",
                name: "fms-order-intention-edit",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-edit.vue"),
                meta: {
                    title: "订单编辑",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "intention_detail/:id(\\d+)?",
                name: "fms-order-intention-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "intention_sign/:id(\\d+)?",
                name: "fms-order-intention-sign",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-sign.vue"),
                meta: {
                    title: "开始签约",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/intention",
                },
            },
            {
                path: "sign",
                name: "fms-order-sign",
                component: () => import("@/views/fms/order/sign"),
                meta: {
                    title: "签约订单",
                    icon: "sign",
                    affix: false,
                    key: "sign",
                },
            },
            {
                path: "purchase",
                name: "fms-order-purchase",
                component: () => import("@/views/fms/order/sign"),
                meta: {
                    title: "采购订单",
                    icon: "purchase",
                    affix: false,
                    key: "purchase",
                },
            },
            {
                path: "sign_create",
                name: "entry-sign-add",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "录入订单",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "sign_edit/:id(\\d+)",
                name: "entry-sign-edit",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "编辑订单",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "sign_detail/:id(\\d+)?",
                name: "fms-order-sign-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/sign-detail.vue"),
                meta: {
                    title: "签约详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/order/sign",
                },
            },
            {
                path: "start_produce/:order_id(\\d+)",
                name: "fms-order-start-produce",
                component: () => import("@/views/fms/order/components/start-produce.vue"),
                meta: {
                    title: "开始生产",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "deliver_detail/:order_id(\\d+)",
                name: "fms-order-deliver-detail",
                component: () => import("@/views/fms/order/components/order-deliver-detail.vue"),
                meta: {
                    title: "发货",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "install/check_info/:order_id(\\d+)/:install_id(\\d+)?",
                name: "fms-order-install-check-info",
                component: () => import("@/views/fms/order/components/install/check-info.vue"),
                meta: {
                    title: "信息确认",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "install/prepare_node/:order_id(\\d+)/:check_id(\\d+)/:install_id(\\d+)?",
                name: "fms-order-install-prepare-node",
                component: () => import("@/views/fms/order/components/install/prepare-node.vue"),
                meta: {
                    title: "配置节点",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "install/appointment/:order_id(\\d+)/:check_id(\\d+)/:install_id(\\d+)?",
                name: "fms-order-install-appointment",
                component: () => import("@/views/fms/order/components/install/appointment.vue"),
                meta: {
                    title: "预约安装",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "acceptance_form/:order_id(\\d+)",
                name: "fms-order-acceptance-form",
                component: () => import("@/views/fms/order/components/acceptance-form.vue"),
                meta: {
                    title: "生成验收单",
                    activeMenu: "/fms/order/sign",
                },
                hidden: true,
            },
            {
                path: "contact",
                name: "contact",
                component: () => import("@/views/cms/service/contact"),
                meta: {
                    title: "联系咨询",
                    icon: "phone-call",
                    affix: false,
                },
            },
            {
                path: "quotation",
                name: "quotation",
                component: () => import("@/views/cms/service/quotation"),
                meta: {
                    title: "报价咨询",
                    icon: "discount",
                    affix: false,
                },
            },
            {
                path: "template",
                name: "fms-order-promise-template",
                component: () => import("@/views/fms/order/promise-template.vue"),
                meta: {
                    title: "文案模板",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/general",
        name: "fms-general",
        meta: {
            title: "综合管理",
            icon: "order-collection",
            parent: "fms",
            permission: "manage_general",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/bill/order-collection",
        children: [
            {
                path: "goods_price",
                name: "fms-mall-goods-price",
                component: () => import("@/views/fms/mall/goods_price.vue"),
                meta: {
                    title: "产品定价",
                    icon: "discount",
                    affix: false,
                },
            },
            {
                path: "goods_price_detail/:id(\\d+)?",
                name: "fms-mall-goods-price-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_price_detail.vue"),
                meta: {
                    title: "定价详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/mall/goods_price",
                },
            },
            {
                path: "handmade_goods",
                name: "fms-mall-handmade-goods",
                component: () => import("@/views/fms/mall/handmade_goods.vue"),
                meta: {
                    title: "服务定价",
                    icon: "discount",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/bill",
        name: "fms-bill",
        meta: {
            title: "财务账单",
            icon: "order-bill",
            parent: "fms",
            permission: "manage_bill",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            await load();
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/bill/order-collection",
        children: [
            {
                path: "order-collection",
                name: "fms-bill-order-collection",
                component: () => import("@/views/fms/bill/order-collection.vue"),
                meta: {
                    title: "合同收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "collection-detail/:id(\\d+)?",
                name: "fms-bill-collection-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/collection-detail.vue"),
                meta: {
                    title: "收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/bill/order-collection",
                },
            },
            {
                path: "oem-detail/:id(\\d+)?",
                name: "fms-bill-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/bill/oem-detail.vue"),
                meta: {
                    title: "OEM收款详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/bill/oem",
                },
            },
            {
                path: "oem",
                name: "fms-bill-oem",
                component: () => import("@/views/fms/bill/oem.vue"),
                meta: {
                    title: "OEM收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "statement",
                name: "fms-bill-statement",
                component: () => import("@/views/fms/bill/statement.vue"),
                meta: {
                    title: "服务收款",
                    icon: "order-collection",
                    affix: false,
                },
            },
            {
                path: "invoice",
                name: "fms-bill-invoice",
                component: () => import("@/views/fms/bill/invoice.vue"),
                meta: {
                    title: "发票中心",
                    icon: "invoice",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/mall",
        name: "fms-mall",
        meta: {
            title: "产品管理",
            icon: "product",
            parent: "fms",
            permission: "manage_product",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/mall/goods",
        children: [
            ...productList,
            // {
            //     path: "goods",
            //     name: "fms-mall-goods",
            //     component: () => import("@/views/fms/mall/goods.vue"),
            //     meta: {
            //         title: "农业设备",
            //         icon: "goods",
            //         key: "goods",
            //         affix: false,
            //         keepAlive: true,
            //     },
            // },
            // {
            //     path: "part",
            //     name: "fms-mall-part",
            //     component: () => import("@/views/fms/mall/goods.vue"),
            //     meta: {
            //         title: "零件管理",
            //         icon: "part",
            //         key: "part",
            //         affix: false,
            //         keepAlive: true,
            //     },
            // },
            // {
            //     path: "extend",
            //     name: "fms-mall-extend",
            //     component: () => import("@/views/fms/mall/goods.vue"),
            //     meta: {
            //         title: "拓展产品",
            //         icon: "goods-extend",
            //         key: "extend",
            //         affix: false,
            //         keepAlive: true,
            //     },
            // },
            // {
            //     path: "oem",
            //     name: "fms-mall-oem",
            //     component: () => import("@/views/fms/mall/goods.vue"),
            //     meta: {
            //         title: "OEM产品",
            //         icon: "oem-goods",
            //         key: "oem",
            //         affix: false,
            //         keepAlive: true,
            //     },
            // },
            {
                path: "good/:id(\\d+)?",
                name: "fms-mall-goods-form",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_form.vue"),
                meta: {
                    title: "产品编辑",
                    icon: "ai",
                    affix: false,
                },
            },
            {
                path: "good_detail/:id(\\d+)?",
                name: "fms-mall-goods-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_detail.vue"),
                meta: {
                    title: "产品详情",
                    icon: "ai",
                    affix: false,
                },
            },
            {
                path: "category",
                name: "fms-mall-category",
                component: () => import("@/views/fms/mall/goods_category.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/inventory",
        name: "fms-inventory",
        meta: {
            title: "库存管理",
            icon: "stock-record",
            parent: "fms",
            permission: "manage_inventory",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/inventory/goods",
        children: [
            {
                path: "goods",
                name: "fms-produce-inventory",
                component: () => import("@/views/fms/produce/inventory/index.vue"),
                meta: {
                    title: "库存管理",
                    icon: "raw-material",
                    affix: false,
                },
            },
            {
                path: "inventory_detail/:id(\\d+)?",
                name: "fms-mall-inventory-detail",
                hidden: true,
                component: () => import("@/views/fms/produce/inventory/detail.vue"),
                meta: {
                    title: "库存详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/fms/produce/inventory",
                },
            },
            {
                path: "stock-record",
                name: "fms-mall-stock-record",
                component: () => import("@/views/fms/mall/stock_record.vue"),
                meta: {
                    title: "流转记录",
                    icon: "exchange",
                    affix: false,
                },
            },
            {
                path: "stat",
                name: "fms-produce-stat",
                component: () => import("@/views/fms/produce/stat/index.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/produce",
        name: "fms-produce",
        meta: {
            title: "生产管理",
            icon: "product",
            parent: "fms",
            permission: "manage_product_make",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/produce/task",
        children: [
            {
                path: "task",
                name: "fms-produce-task",
                component: () => import("@/views/fms/produce/task/index.vue"),
                meta: {
                    title: "生产任务",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "task-log-template",
                name: "fms-produce-task-log-template",
                component: () => import("@/views/fms/produce/task_log_template/index.vue"),
                meta: {
                    title: "流程模版",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "task-edit/:id(\\d+)?",
                name: "fms-produce-task-edit",
                component: () => import("@/views/fms/produce/task/form.vue"),
                meta: {
                    title: "编辑生产任务",
                    activeMenu: "/fms/produce/task",
                },
                hidden: true,
            },
            {
                path: "task-detail/:id(\\d+)",
                name: "fms-produce-task-detail",
                component: () => import("@/views/fms/produce/task/detail.vue"),
                meta: {
                    title: "生产任务详情",
                    activeMenu: "/fms/produce/task",
                },
                hidden: true,
            },

            {
                path: "plan",
                name: "fms-produce-plan",
                component: () => import("@/views/fms/produce/plan/index.vue"),
                meta: {
                    title: "生产规划",
                    icon: "plan",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/after-sales",
        name: "fms-after-sales",
        meta: {
            title: "售后中心",
            icon: "chatbot",
            parent: "fms",
            permission: "manage_after_sale",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            await load();
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/after-sales/workflows",
        children: [
            {
                path: "workflows",
                name: "fms-workflows",
                component: () => import("@/views/fms/after-sales/workflows.vue"),
                meta: {
                    title: "售后工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            // {
            //     path: "workflows-mine",
            //     name: "fms-workflows-mine",
            //     component: () => import("@/views/fms/after-sales/workflows-mine.vue"),
            //     meta: {
            //         title: "我的工单",
            //         icon: "my-workflows",
            //         affix: false,
            //     },
            // },
            {
                path: "create",
                name: "create-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "创建工单",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "edit-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "编辑工单",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "workflow-detail",
                component: () => import("@/views/fms/after-sales/components/workflow-detail.vue"),
                meta: {
                    title: "工单详情",
                    activeMenu: "/fms/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "fms-after-sales-analysis",
                component: () => import("@/views/fms/after-sales/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "fms-after-sales-types",
                component: () => import("@/views/fms/after-sales/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                    permission: "fms_manage_after_sales",
                },
            },
            {
                path: "template",
                name: "fms-after-sales-template",
                component: () => import("@/views/fms/after-sales/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "ewx-phone",
                name: "enterprise-wechat-phone",
                component: () => import("@/views/fms/after-sales/ewx-phone.vue"),
                meta: {
                    title: "企微电话",
                    icon: "wechat-phone",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/repair",
        name: "repair",
        meta: {
            title: "安装维修",
            icon: "maintenance",
            parent: "fms",
            permission: "manage_repair",
        },
        beforeEnter: async (to, from, next) => {
            store.dispatch("goods/loadCategories");
            next();
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/repair/install",
        children: [
            {
                path: "install",
                name: "fms-repair-install",
                component: () => import("@/views/fms/repair/install-workflows.vue"),
                meta: {
                    title: "安装工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "install-detail/:id(\\d+)",
                name: "fms-repair-install-detail",
                component: () => import("@/views/fms/repair/components/install-detail.vue"),
                meta: {
                    title: "安装任务详情",
                    activeMenu: "/fms/repair/install",
                },
                hidden: true,
            },
            {
                path: "install-edit/:id(\\d+)",
                name: "fms-repair-install-edit",
                component: () => import("@/views/fms/repair/components/install-edit.vue"),
                meta: {
                    title: "安装任务编辑",
                    activeMenu: "/fms/repair/install",
                },
                hidden: true,
            },
            {
                path: "task",
                name: "fms-repair-task",
                component: () => import("@/views/fms/repair/workflows.vue"),
                meta: {
                    title: "维修工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "create",
                name: "fms-repair-task-create",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "创建维修任务",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "fms-repair-task-edit",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "编辑维修任务",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "fms-repair-task-detail",
                component: () => import("@/views/fms/repair/components/workflow-detail.vue"),
                meta: {
                    title: "维修任务详情",
                    activeMenu: "/fms/repair/task",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "fms-repair-analysis",
                component: () => import("@/views/fms/repair/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "fms-repair-types",
                component: () => import("@/views/fms/repair/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "template",
                name: "fms-repair-template",
                component: () => import("@/views/fms/repair/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/fms/oem",
        name: "fms-oem",
        meta: {
            title: "OEM订单",
            icon: "oem",
            parent: "fms",
            affix: false,
            permission: "manage_oem",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/fms/oem/list",
        children: [
            {
                path: "oem-card",
                name: "fms-bill-oem-card",
                component: () => import("@/views/fms/bill/oem-card.vue"),
                meta: {
                    title: "收款卡号",
                    icon: "card",
                    affix: false,
                },
            },
            {
                path: "oem-card-log",
                name: "fms-bill-oem-card-log",
                component: () => import("@/views/fms/bill/oem-card-log.vue"),
                meta: {
                    title: "收款日志",
                    icon: "card",
                    affix: false,
                },
            },
            {
                path: "list",
                name: "fms-oem-list",
                component: () => import("@/views/fms/order/oem"),
                meta: {
                    title: "OEM订单",
                    icon: "oem",
                    affix: false,
                },
            },
            {
                path: "detail/:id(\\d+)?",
                name: "fms-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/oem-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "oem",
                    affix: false,
                    activeMenu: "/fms/oem/list",
                },
            },
            {
                path: "inventory",
                name: "fms-oem-inventory",
                component: () => import("@/views/fms/produce/oem_inventory/index.vue"),
                meta: {
                    title: "客户库存",
                    icon: "oem-goods",
                    parent: "fms",
                },
            },
            {
                path: "inv-adjust",
                name: "fms-produce-inv-adjust",
                component: () => import("@/views/fms/produce/inv_adjust/index.vue"),
                meta: {
                    title: "库存流转",
                    icon: "exchange",
                    affix: false,
                },
            },
        ],
    },
];
